import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Size Taahütüm' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
          Eğer projelerinizde yardıma ihtiyacınız varsa veya yeni bir şeyler yapmak istiyorsanız
          lütfen bunu bana iletmekte çekinmeyin seve seve yardımcı olabilirim.
      </Text>
    </Card>
  </Section>
)

export default Commitment
