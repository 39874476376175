import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

export default () => (
  <>
    <Seo title='İletişim' />
    <Divider />
    <Stack>
      <Main>
      <PageTitle
          header="İletişim"
          subheader='Eğer bir şikayetiniz, isteğiniz varsa lütfen bunu bildirmekten çekinmeyin.
           Geliştirmemi istediğiniz bir yer varsa veya beğenmediğiniz bir yer varsa lütfen belirtin.
           Eğer sizin için geliştirmemi istediğiniz bir uygulamanız varsa da yazabilirsiniz. '
      />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </>
)
