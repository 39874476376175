import React from 'react'
import { Card, Text } from 'theme-ui'
//import { FaPhone, FaEnvelope, FaLocationArrow, FaTelegram } from 'react-icons/fa'
import Section from '@components/Section'
//import useSiteMetadata from '@helpers/useSiteMetadata'

const ContactInfo = () => {
  //const { phone, address, email, telegram } = useSiteMetadata()

  return (
    <Section aside title='Benimle Birlikte Çalışın'>
      <Card variant='paper'>
        <Text variant='p'>Benimle birlikte çalışmak mı istiyorsunuz?</Text>
        <Text variant='p'>
          Bana bu iletişim formu aracılığıyla ulaşabilirsiniz.
        </Text>
      </Card>
    </Section>
  )
}

export default ContactInfo
