import { useState, useEffect, useCallback } from 'react'
//const contentful = require('contentful-management')
import axios from 'axios'

const useForm = () => {
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [values, setValues] = useState({})
  const [success, setSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitting(true)
    const data = new FormData(e.target)
    const newValues = {}
    for (var pair of data.entries()) {
      newValues[pair[0]] = pair[1]
    }
    setValues(newValues)
    setSubmitted(true)
  }

  const sendValues = useCallback(() => {
    axios.post('https://services.omerharuncetin.com/addMessage',
        {
          email : values.email,
          subject : values.subject,
          message : values.message
        }

    ).then(data => {
      setSuccess(true);
      setSubmitting(false)
    }).catch(error => {
      setSuccess(true);
      setSubmitting(false)
    });
  }, [values])

  useEffect(() => {
    if (submitted) {
      sendValues()
    }
  }, [submitted, sendValues])

  return {
    handleSubmit,
    submitting,
    submitted,
    success
  }
}

export default useForm
